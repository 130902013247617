import PhoneIcon from "./phone.png";
import ZaloIcon from "./zalo.png";
import styles from "./styles.module.scss";

export default function StickyRightButton() {
   return (
      <div className={styles["sticky-right-button"]}>
         <a className={styles["contact-icon"]} href="tel:+84848847938" rel="nofollow">
            <img src={PhoneIcon.src} alt="Space T phone" />
         </a>
         <a className={styles["contact-icon"]} href="https://zalo.me/1171954959320860843" target="_blank" rel="nofollow">
            <img src={ZaloIcon.src} alt="Space T zalo" />
         </a>
      </div>
   );
}
